var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'youtube',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12"},[(_vm.block.block_title === null)?_c('h2',[_vm._v("\n\t\t\t\t\t\tПосмотрите, как это было\n\t\t\t\t\t")]):_c('h2',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"youtube_block"},[_c('CoolLightBox',{ref:"CoolLightBox",attrs:{"items":_vm.videosSource,"full-screen":true,"index":_vm.index},on:{"close":function($event){_vm.index = null}}}),_vm._v(" "),_c('ul',{staticClass:"youtube_list row"},_vm._l((_vm.visibleVideos),function(item,imageIndex){return _c('li',{key:imageIndex,staticClass:"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"},[_c('div',{staticClass:"youtube-item",on:{"click":function($event){_vm.index = imageIndex}}},[_c('div',{staticClass:"youtube-image icon-video"},[_c('b-img-lazy',{attrs:{"src":item.preview,"offset":0}})],1),_vm._v(" "),(item.title !== null)?_c('div',{staticClass:"youtube-title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(item.description !== null)?_c('div',{staticClass:"youtube-viewCount"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.description)+"\n\t\t\t\t\t\t")]):_vm._e()])])}),0)],1),_vm._v(" "),(_vm.expandable)?_c('div',{staticClass:"panel_footer"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.videosSource.length > 4),expression:"videosSource.length > 4"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.videoLimit === _vm.defaultVideoLimit ? 'Ещё' : 'Скрыть'),expression:"videoLimit === defaultVideoLimit ? 'Ещё' : 'Скрыть'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-resize icon-chevron",class:_vm.videoLimit === _vm.defaultVideoLimit ? 'collapsed' : null,attrs:{"aria-label":"Раскрыть"},on:{"click":_vm.toggle}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }