<template>
	<div
		:class="['panel', 'youtube',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2 v-if="block.block_title === null">
							Посмотрите, как это было
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>
			<div class="youtube_block">
				<CoolLightBox
					ref="CoolLightBox"
					:items="videosSource"
					:full-screen="true"
					:index="index"
					@close="index = null"
				/>
				<ul class="youtube_list row">
					<li
						v-for="(item, imageIndex) in visibleVideos"
						:key="imageIndex"
						class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
					>
						<div class="youtube-item" @click="index = imageIndex">
							<div class="youtube-image icon-video">
								<b-img-lazy
									:src="item.preview"
									:offset="0"
								/>
							</div>
							<div class="youtube-title" v-if="item.title !== null">
								{{ item.title }}
							</div>
							<div class="youtube-viewCount" v-if="item.description !== null">
								{{ item.description }}
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div v-if="expandable" class="panel_footer">
				<button
					v-show="videosSource.length > 4"
					v-b-tooltip.hover.top="videoLimit === defaultVideoLimit ? 'Ещё' : 'Скрыть'"
					class="btn btn-resize icon-chevron"
					aria-label="Раскрыть"
					:class="videoLimit === defaultVideoLimit ? 'collapsed' : null"
					@click="toggle"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import panel from '~/mixins/panel';
import lightboxMixin from '~/mixins/lightboxMixin';

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

const DEFAULT_VIDEO_LIMIT = 4;

export default {
	name: 'VideoUnpublishBlock',
	components: {
		CoolLightBox,
	},
	mixins: [panel, lightboxMixin],
	data() {
		return {
			videos: [],
			index: null,
			videoLimit: DEFAULT_VIDEO_LIMIT,
		};
	},
	computed: {
		expandable() {
			const MAX_VIDEOS_IN_COLUMN = 4;

			const { expandable } = this.block;
			if (expandable !== undefined && expandable !== null) {
				return expandable;
			}

			return (this.block.items && this.block.items.length > MAX_VIDEOS_IN_COLUMN);
		},
		defaultVideoLimit() {
			return this.block.collapsed_count || DEFAULT_VIDEO_LIMIT;
		},
		visibleVideos() {
			return this.block.items.slice(0, this.videoLimit);
		},
		videosSource() {
			return Array.isArray(this.block.items)
				? this.block.items.map((item) => ({
					type: 'video',
					thumb: item.preview,
					src: item.embed,
				}))
				: [];
		},
	},
	watch: {
		defaultVideoLimit: {
			handler(defaultVideoLimit, old) {
				if (defaultVideoLimit !== old) {
					this.videoLimit = defaultVideoLimit;
				}
			},
			immediate: true,
		},
	},
	methods: {
		toggle() {
			if (this.videoLimit === this.defaultVideoLimit) {
				this.videoLimit = 100;
			} else {
				this.videoLimit = this.defaultVideoLimit;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/home/panel-youtube";
</style>
