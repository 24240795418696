export default {
	mounted() {
		if 	(this.$refs.CoolLightBox) {
			this.addRutubePlayToLightbox();
		}
	},
	methods: {
		addRutubePlayToLightbox() {
			const coolLightBox = this.$refs.CoolLightBox;

			coolLightBox.getRutubeUrl = this.getRutubeUrl;
			coolLightBox.getVideoUrl = this.getVideoUrl;
		},
		getRutubeUrl(url) {
			const result = url.match(/^.+rutube.ru\/video\/(.*\/)?([\w]+)(.*)\/?/);
			const regex = /video\/(.*)\//;
			const match = url.match(regex);

			if (result !== null && match) {
				const id = match[1];
				return `https://rutube.ru/play/embed/${id}`;
			}

			return false;
		},
		getVideoUrl(itemSrc) {
			const self = this.$refs.CoolLightBox;
			const videoUrls = [
				self.getYoutubeUrl(itemSrc),
				self.getRutubeUrl(itemSrc),
				self.getVimeoUrl(itemSrc),
				self.checkIsMp4(itemSrc),
			];
			return videoUrls.find((url) => url) || false;
		},
	},
};
